<template>
  <div>
    
  </div>
</template>
<script>

export default {
  name: 'Verify',
  data() {
    return {
      usuario: false,
      usuarioAux: false,

    }
  },

  created(){
    
    this.usuarioAux = this.$store.getters.getUsuario;
    
    if(this.usuarioAux){
      console.log(this.$store.getters.getToken);
        this.$http.get(this.$urlGApi+'02et92p015jdglv/members/'+ this.$store.getters.getUsuarioEmail,
        {"headers": { "Authorization": "Bearer "+ this.$store.getters.getToken}}) 
          .then(response => 
          {
            if(response.status){
              this.$router.push('/comercial');
            }
          })
              .catch(e => {
                console.log(e)
                alert("Erro! Tente novamente mais tarde!");
              })       
    }
    
  },
    
  mounted(){
    let that = this
    let checkGauthLoad = setInterval(function(){
      that.isInit = that.$gAuth.isInit
      that.isSignIn = that.$gAuth.isAuthorized
      if(that.isInit) clearInterval(checkGauthLoad)
    }, 1000);
  }    
        
        
}     


</script>
